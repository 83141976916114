import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { PageSectionHeaderEntry } from "frontend/contentful/schema/blocks";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { SectionHeader } from "design-system/components/blocks/section-header/section-header";
import { createCtaLinkProps } from "../primitives/cta-link.props";

export const ContentfulPageSectionHeader =
  createContentfulComponent<PageSectionHeaderEntry>(
    ({ entry, createEditAttributes }) => {
      return (
        <SectionHeader
          title={entry.fields.title}
          subTitle={entry.fields.subtitle}
          description={
            entry.fields.description && (
              <RenderContentfulRichText document={entry.fields.description} />
            )
          }
          cta={createCtaLinkProps(entry.fields.link)}
          editAttributes={{
            title: createEditAttributes({ entry, fieldId: "title" }),
            subTitle: createEditAttributes({ entry, fieldId: "subtitle" }),
            description: createEditAttributes({
              entry,
              fieldId: "description",
            }),
          }}
        />
      );
    },
  );
